var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { router } from '../QRPortalRouter';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import moment from 'moment';
import { QrPortalAPI } from 'qrportal-api/cerum/qrportal/qr_portal/QrPortalAPI';
import { isErrorHTTP, isSuccessHTTP } from 'qrportal-api/APIRestClient';
import { LogbookCleaningLoading } from '../components/LogbookCleaningLoading';
let QRPortalResourceCleaningView = class QRPortalResourceCleaningView extends LitElement {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.cleaningDocuments = [];
    }
    static get is() {
        return 'qr-portal-resource-cleaning-view';
    }
    static get styles() {
        return css `
            .container {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .previous .date {
                font-size: 180%;
            }

            .next {
                margin-top: 40px;
            }

            .next .date {
                font-size: 150%;
            }

            .missing {
                font-size: 150%;
                margin: 60px 0;
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this.hash = router.location.params.hash;
    }
    /** @override */ render() {
        return html `<div class="container">
            ${this.logbookOverview ? this._renderLogbookOverview(this.logbookOverview) : this._renderEmpty()}
        </div>`;
    }
    _renderLogbookOverview(logbookOverview) {
        var _a;
        return html `<div class="container">
            <h1>${logbookOverview.resource.name}</h1>

            ${logbookOverview.last
            ? html `<div class="previous">
                      <div>Sist rengjort</div>
                      <div class="date">
                          ${moment(logbookOverview.last.from)
                .locale('nb-no')
                .format('D. MMMM YYYY [kl.] HH:MM')}
                      </div>
                      <div>
                          av
                          ${logbookOverview.provider.name}${((_a = logbookOverview.last.operator) === null || _a === void 0 ? void 0 : _a.first_name)
                ? '/ ' + logbookOverview.last.operator.first_name
                : ''}
                      </div>
                  </div>`
            : ''}
            ${logbookOverview.next
            ? html `<div class="next">
                      <div>Neste rengjøring</div>
                      <div class="date">
                          ${moment(logbookOverview.next.from).locale('nb-no').format('D. MMMM YYYY')}
                      </div>
                  </div>`
            : ''}
            ${this.cleaningDocuments.length
            ? html `<a style="margin-top: 2rem;" href="${window.location + '/documents'}">
                      Klikk her for å gå til dokumenter
                  </a>`
            : ''}
        </div>`;
    }
    _renderEmpty() {
        if (this.isLoading) {
            return html `<logbook-cleaning-loading></logbook-cleaning-loading>`;
        }
        return html `<div class="missing">Mangler ressurs</div>`;
    }
    _hashObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            yield this._loadCleaningLogbook();
            yield this._loadCleaningDocuments();
            this.isLoading = false;
        });
    }
    _loadCleaningLogbook() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const logbookOverviewResult = yield QrPortalAPI.getV1QrportalCleaningUsingHash(this.hash);
            if (isSuccessHTTP(logbookOverviewResult)) {
                this.logbookOverview = logbookOverviewResult;
            }
            else if (isErrorHTTP(logbookOverviewResult)) {
                SnackBar.pushError(logbookOverviewResult.error);
            }
        });
    }
    _loadCleaningDocuments() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const logbookDocumentsResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHash(this.hash);
            if (isErrorHTTP(logbookDocumentsResult)) {
                return;
            }
            this.cleaningDocuments = logbookDocumentsResult;
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'hash':
                    this._hashObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
QRPortalResourceCleaningView._deps = [LogbookCleaningLoading];
__decorate([
    state(),
    __metadata("design:type", String)
], QRPortalResourceCleaningView.prototype, "hash", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], QRPortalResourceCleaningView.prototype, "logbookOverview", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], QRPortalResourceCleaningView.prototype, "isLoading", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], QRPortalResourceCleaningView.prototype, "cleaningDocuments", void 0);
QRPortalResourceCleaningView = __decorate([
    customElement(QRPortalResourceCleaningView.is)
], QRPortalResourceCleaningView);
export { QRPortalResourceCleaningView };
