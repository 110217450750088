var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { isErrorHTTP } from 'qrportal-api/APIRestClient';
import { QrPortalAPI } from 'qrportal-api/cerum/qrportal/qr_portal/QrPortalAPI';
import { router } from '../QRPortalRouter';
import { DocumentViewer } from 'common-uicomponents/components/DocumentViewer';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { Details } from '@vaadin/details';
let QRPortalResourceCleaningDocumentView = class QRPortalResourceCleaningDocumentView extends LitElement {
    constructor() {
        super(...arguments);
        this.cleaningDocuments = [];
        this.expandedDocument = new Set();
    }
    static get is() {
        return 'qr-portal-resource-cleaning-document-view';
    }
    static get styles() {
        return css `
            .documents-container {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .document-box {
                background-color: #f0f0f0;
                border-radius: 6px;
                font-size: 14px;
                box-shadow:
                    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow: hidden;
                min-height: 50px;
                justify-content: center;
                background-color: white;
                padding: 6px;
            }

            img {
                height: 100%;
                object-fit: cover;
            }

            .document-text {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 6px;
                white-space: nowrap;
            }

            .document-text[subdocument='true'] {
                margin-left: 6px;
            }

            vaadin-details[subdocument='true'] {
                margin-left: 6px;
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this.hash = router.location.params.hash;
    }
    /** @override */ render() {
        return html ` ${this.cleaningDocuments.length
            ? html `<h2 style="margin-top: 3rem">Dokumenter</h2>
                  <div class="documents-container">
                      ${this.cleaningDocuments.map((document) => {
                return html `<div
                              class="document-box"
                              @click="${() => this._onDocumentClick(document)}"
                          >
                              ${this._renderDocument(document)}
                          </div>`;
            })}
                  </div>`
            : ''}`;
    }
    _renderDocument(document) {
        return this._isFolder(document)
            ? this._renderFolder(document, false)
            : this._renderDocumentFile(document, false);
    }
    _renderDocumentFile(document, isSubdocument) {
        var _a;
        return html `<span subDocument="${isSubdocument}" class="document-text">
            <vaadin-icon style="width: 18px" icon="vaadin:file"></vaadin-icon> ${(_a = document.metadata) === null || _a === void 0 ? void 0 : _a.title}
        </span>`;
    }
    _renderFolder(document, isSubdocument) {
        var _a, _b, _c;
        return html `<vaadin-details
            subDocument="${isSubdocument}"
            summary="${(_b = (_a = document.metadata) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : ''}"
            ?open="${this.expandedDocument.has(document)}"
            @click="${() => {
            if (this.expandedDocument.has(document)) {
                this.expandedDocument.delete(document);
            }
            else {
                this.expandedDocument.add(document);
            }
        }}"
        >
            <div style="display: flex; flex-direction: column">
                ${((_c = document.files) === null || _c === void 0 ? void 0 : _c.length)
            ? document.files.map((subdocument) => this._isFolder(subdocument)
                ? this._renderFolder(subdocument, true)
                : this._renderDocumentFile(subdocument, true))
            : html `<span class="document-text">Ingen dokumenter</span>`}
            </div>
        </vaadin-details>`;
    }
    _isFolder(document) {
        return document.type === 'FOLDER';
    }
    _loadCleaningDocuments() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const logbookDocumentsResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHash(this.hash);
            if (isErrorHTTP(logbookDocumentsResult)) {
                return;
            }
            for (const document of logbookDocumentsResult) {
                const parentFolder = this.cleaningDocuments.find((cleaningDocument) => cleaningDocument.node_id === document.parent_node_id);
                if (parentFolder) {
                    if (parentFolder.files) {
                        parentFolder.files.push(document);
                    }
                    else {
                        parentFolder.files = [document];
                    }
                }
                else {
                    this.cleaningDocuments.push(document);
                }
            }
            this.requestUpdate('cleaningDocuments');
        });
    }
    _loadDocument(nodeId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hash) {
                return;
            }
            const loadDocumentResult = yield QrPortalAPI.getV1QrportalDocumentsUsingHashNodeid(this.hash, nodeId, { preview: false });
            if (isErrorHTTP(loadDocumentResult)) {
                SnackBar.pushError(loadDocumentResult.error);
                return;
            }
            return URL.createObjectURL(loadDocumentResult);
        });
    }
    _onDocumentClick(document) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (!this.hash || document.type === 'FOLDER') {
                return;
            }
            OverlayWindow.asyncInject({
                title: (_b = (_a = document.metadata) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
                componentTagName: DocumentViewer.is,
                attributes: {
                    documentUrl: yield this._loadDocument(document.node_id),
                    documentMetadata: JSON.stringify({ title: (_d = (_c = document.metadata) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : '' }),
                    showDownload: true,
                },
                fullscreen: true,
            });
        });
    }
    _hashObserver() {
        this._loadCleaningDocuments();
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'hash':
                    this._hashObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
QRPortalResourceCleaningDocumentView._deps = [Details];
__decorate([
    state(),
    __metadata("design:type", String)
], QRPortalResourceCleaningDocumentView.prototype, "hash", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], QRPortalResourceCleaningDocumentView.prototype, "cleaningDocuments", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], QRPortalResourceCleaningDocumentView.prototype, "expandedDocument", void 0);
QRPortalResourceCleaningDocumentView = __decorate([
    customElement(QRPortalResourceCleaningDocumentView.is)
], QRPortalResourceCleaningDocumentView);
export { QRPortalResourceCleaningDocumentView };
